<template>
  <vue-good-table
    :columns="columns"
    :rows="abilities"
    :select-options="{
      enabled: false,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }"
  >
    <template slot="loadingContent">
      <b-spinner
        label="Loading"
        type="grow"
      />
    </template>
    <template
      slot="table-row"
      slot-scope="props"
    >
      <!-- Column: Code -->
      <span v-if="props.column.field === 'other'">
        <b-form-checkbox
          v-for="eachPermission in props.row.items.filter(object => !['create', 'read', 'update', 'delete'].includes(object.action))"
          :key="eachPermission.action"
          :checked="eachPermission.checked"
          @change="changeCell(props.column.field, eachPermission.action, props.row.originalIndex)"
        >{{ eachPermission.actionName }}</b-form-checkbox>

      </span>

      <span v-else-if="props.column.field !== 'title'">
        <b-form-checkbox
          v-if="editable === true && props.row.items.filter(item => item.action === props.column.field).length > 0"
          :checked="props.row.items.filter(item => item.action === props.column.field).length > 0 ? props.row.items.filter(item => item.action === props.column.field)[0].checked : false"
          @change="changeCell(props.column.field, 'quantity', props.row.originalIndex)"
        >{{ props.column.label }}</b-form-checkbox>

      </span>

      <!-- Column: Common -->
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>
    <div slot="emptystate">
      <div class="text-center text-muted">
        {{ t('Không có bản ghi nào!') }}
      </div>
    </div>
  </vue-good-table>
</template>

<script>
import {
  BFormCheckbox,
  BSpinner,
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import { useUtils as useI18nUtils } from '@core/libs/i18n';

export default {
  components: {
    BFormCheckbox,
    BSpinner,

    VueGoodTable,
  },
  props: {
    defaultValue: {
      type: Array,
      default: () => [],
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      abilities: this.defaultValue,
    };
  },
  watch: {
    defaultValue(val) {
      this.abilities = val;
    },
  },
  created() {

  },

  methods: {

    changeCell(field, value, row) {
      const ability = this.abilities[row];

      if (field === 'all') {
        const { items } = ability;

        const itemAll = items.filter(obj => obj.action === 'all');
        if (itemAll && itemAll.length > 0 && itemAll[0].checked) {
          ability.items = ability.items.map(item => ({ ...item, checked: false }));
        } else {
          ability.items = ability.items.map(item => ({ ...item, checked: true }));
        }
      } else {
        //
        // eslint-disable-next-line no-restricted-syntax
        for (const item of ability.items) {
          if (item.action === field || item.action === value) {
            item.checked = !item.checked;
            break;
          }
        }

        // ability.items[0].checked = ability.items.filter(obj => obj.action !== 'all' && !obj.checked).length === 0;

        // neu co 1 item k check thi cai item all cung khong check
      }

      this.abilities[row] = ability;

      this.onChange();
    },

    onChange() {
      this.$emit('input', this.abilities);
    },
  },
  setup() {
    const { t } = useI18nUtils();
    // Table Handlers
    const columns = [
      {
        label: t('Nhóm quyền'),
        field: 'title',
        sortable: false,
      },
      // {
      //   label: 'Tất cả',
      //   field: 'all',
      //   sortable: false,
      //   width: '100px',
      //   thClass: 'text-center',
      //   tdClass: 'text-center',
      // },
      {
        label: t('Xem'),
        field: 'read',
        sortable: false,
        width: '100px',
        thClass: 'text-center',
        tdClass: 'text-center',
      },
      {
        label: t('Thêm'),
        field: 'create',
        sortable: false,
        width: '100px',
        thClass: 'text-center',
        tdClass: 'text-center',
      },
      {
        label: t('Sửa'),
        field: 'update',
        sortable: false,
        width: '100px',
        thClass: 'text-center',
        tdClass: 'text-center',
      },
      {
        label: t('Xóa'),
        field: 'delete',
        sortable: false,
        width: '100px',
        thClass: 'text-center',
        tdClass: 'text-center',
      },
      {
        label: t('Quyền khác'),
        field: 'other',
        sortable: false,
        thClass: 'text-center',
      },
    ];

    return {
      columns,
      t,
    };
  },
};
</script>
